import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import HeroBody from "./hero-body";

// Styles
import styles from "./hero.module.scss";

const Hero = (props) => {
  //console.log("Hero props: ", props);

  /* ------------------------------------------------------------------------
  FULL WIDTH HERO IMAGE SIZES
    XXS: <375px (320px min supported) - image size: 300px * 125px
    XS:  376-599px - image size: 600px * 250px 
    SM:  600-767px - image size: 768px * 250px
    MD:  768-991px - image size: 992px * 300px
    LG:  992-1199px - image size: 1200px * 350px
    XL:  1200-1919px - image size: 1920px * 350px
    XXL: >=1920 (2500px max supported) - image size: 2500px * 350px
  ---------------------------------------------------------------------------
  NOTE: The standard naming conventions for hero image fragments and image data props are:
    "heroImageFragmentXXS" --> "props.imgVariableXXS"
    "heroImageFragmentXS"  --> "props.imgVariableXS"
    "heroImageFragmentSM"  --> "props.imgVariableSM"
    "heroImageFragmentMD"  --> "props.imgVariableMD"
    "heroImageFragmentLG"  --> "props.imgVariableLG"
    "heroImageFragmentXL"  --> "props.imgVariableXL"
    "heroImageFragmentXXL" --> "props.imgVariableXXL"
  ----------------------------------------------------------------------------
  NOTE: the inline styles for "maxHeight" are necessary to keep the containing block 
  from overflowing below the hero image height. Some strange quirk with the positioning.
  -------------------------------------------------------------------------- */

  return (
    <div id={props.id} className={`hero ${styles.hero}`}>
      {/* XXS */}
      {props.imgVariableXXS && (
        <div id="hero-image-container-xxs" className="d-block d-xs-none" style={{ maxHeight: "125px" }}>
          <GatsbyImage
            loading="eager"
            image={props.imgVariableXXS}
            className={`hero-image ${styles.heroImage}`}
            alt={props.altText}
          />
        </div>
      )}
      {/* XS - TODO: change height to 200px once XS hero images have been converted to this height. */}
      {props.imgVariableXS && (
        <div id="hero-image-container-xs" className="d-none d-xs-block d-sm-none" style={{ maxHeight: "250px" }}>
          <GatsbyImage
            loading="eager"
            image={props.imgVariableXS}
            className={`hero-image ${styles.heroImage}`}
            alt={props.altText}
          />
        </div>
      )}
      {/* SM */}
      {props.imgVariableSM && (
        <div id="hero-image-container-sm" className="d-none d-sm-block d-md-none" style={{ maxHeight: "250px" }}>
          <GatsbyImage
            loading="eager"
            image={props.imgVariableSM}
            className={`hero-image ${styles.heroImage}`}
            alt={props.altText}
          />
        </div>
      )}
      {/* MD */}
      {props.imgVariableMD && (
        <div id="hero-image-container-md" className="d-none d-md-block d-lg-none" style={{ maxHeight: "300px" }}>
          <GatsbyImage
            loading="eager"
            image={props.imgVariableMD}
            className={`hero-image ${styles.heroImage}`}
            alt={props.altText}
          />
        </div>
      )}
      {/* LG */}
      {props.imgVariableLG && (
        <div id="hero-image-container-lg" className="d-none d-lg-block d-xl-none" style={{ maxHeight: "350px" }}>
          <GatsbyImage
            loading="eager"
            image={props.imgVariableLG}
            className={`hero-image ${styles.heroImage}`}
            alt={props.altText}
          />
        </div>
      )}
      {/* XL */}
      {props.imgVariableXL && (
        <div id="hero-image-container-xl" className="d-none d-xl-block d-xxl-none" style={{ maxHeight: "350px" }}>
          <GatsbyImage
            loading="eager"
            image={props.imgVariableXL}
            className={`hero-image ${styles.heroImage}`}
            alt={props.altText}
          />
        </div>
      )}
      {/* XXL */}
      {props.imgVariableXXL && (
        <div id="hero-image-container-xxl" className="d-none d-xxl-block" style={{ maxHeight: "350px" }}>
          <GatsbyImage
            loading="eager"
            image={props.imgVariableXXL}
            className={`hero-image ${styles.heroImage}`}
            alt={props.altText}
          />
        </div>
      )}

      {/* HERO BODY */}
      {props.heroBody && <HeroBody {...props.heroBody} />}
    </div>
  );
};
export default Hero;

Hero.defaultProps = {
  id: "default-hero",
  altText: "A bank representative greeting a customer at a desk",
  heroBody: null,
  // Hero image variables for all breakpoints
  imgVariableXXS: null,
  imgVariableXS: null,
  imgVariableSM: null,
  imgVariableMD: null,
  imgVariableLG: null,
  imgVariableXL: null,
  imgVariableXXL: null
};
